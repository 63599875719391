<template>
  <EditComponent
    class="additional-information-view"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('metaData.fields.naturalUser.additionalInformation')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
  >
    <v-row>     
      <v-col cols="12" sm="4">
        <SelectComponent
          :readonly="readonly"
          v-model="naturalUser.maritalStatus"
          :label="$t('metaData.fields.naturalUser.maritalStatus.label')"
          :placeholder="$t('metaData.fields.naturalUser.maritalStatus.label')"
          :optionsList="statusList"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.maritalStatus') ? niceErrorMessage(errors.naturalUser.maritalStatus) : ''
          "
          :nextBtnClicked="nextBtnClicked"
          valueKey="value"
          valueLabel="label"
        />
      </v-col>

      <!--
      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.taxId"
          :label="$t('metaData.fields.naturalUser.taxId.label')"
          :placeholder="$t('metaData.fields.naturalUser.taxId.placeholder')"
          :error="checkIfExists('errors.naturalUser.taxId') ? niceErrorMessage(errors.naturalUser.taxId) : ''"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.phone"
          :label="$t('metaData.fields.naturalUser.phone.label')"
          :placeholder="$t('metaData.fields.naturalUser.phone.placeholder')"
          :error="checkIfExists('errors.naturalUser.phone') ? niceErrorMessage(errors.naturalUser.phone) : ''"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.phoneMobile"
          :label="$t('metaData.fields.naturalUser.phoneMobile.label')"
          :placeholder="$t('metaData.fields.naturalUser.phoneMobile.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.phoneMobile') ? niceErrorMessage(errors.naturalUser.phoneMobile) : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
      
      <v-col cols="12" sm="4">
        <div>
          <div class="checkbox-w-tooltip">
            <input type="checkbox" id="isUSTaxed" v-model="naturalUser.isUSTaxed" v-on:change="onSave" />
            <label for="isUSTaxed">
              {{ $t('metaData.fields.naturalUser.isUSTaxed.label') }}
            </label>
            <span class="tooltip-btn" v-tooltip="$t('metaData.fields.naturalUser.isUSTaxed.tooltip')">i</span>
          </div>
          <div v-if="naturalUser.isUSTaxed && checkIfExists('errors.naturalUser.isUSTaxed')" class="error">
            {{ niceErrorMessage(errors.naturalUser.isUSTaxed) }}
          </div>
        </div>
        <div>
          <div class="checkbox-w-tooltip">
            <input type="checkbox" id="isPEP" v-model="naturalUser.isPEP" v-on:change="onSave" />
            <label for="isPEP">
              {{ $t('metaData.fields.naturalUser.isPEP.label') }}
            </label>
            <span class="tooltip-btn" v-tooltip="$t('metaData.fields.naturalUser.isPEP.tooltip')">i</span>
          </div>
          <div v-if="checkIfExists('errors.naturalUser.isPEP')" class="error">
            {{ niceErrorMessage(errors.naturalUser.isPEP) }}
          </div>
        </div>
      </v-col>
      -->

      <v-col cols="12" sm="4">
        <SelectComponent
          :readonly="readonly"
          v-model="naturalUser.job.group"
          :label="$t('metaData.fields.naturalUser.job.group.label')"
          :placeholder="$t('metaData.fields.naturalUser.job.group.label')"
          :optionsList="jobList"
          :onSelect="onSave"
          :error="checkIfExists('errors.naturalUser.job.group') ? niceErrorMessage(errors.naturalUser.job.group) : ''"
          :nextBtnClicked="nextBtnClicked"
          valueKey="value"
          valueLabel="job"
        />
      </v-col>
      <v-col v-if="naturalUser.job.group == 'selfEmployed'" cols="12" sm="4">
        <SelectComponent
          :readonly="readonly"
          v-model="naturalUser.job.selfEmployedSector"
          :label="$t('metaData.fields.naturalUser.job.selfEmployedSector.label')"
          :placeholder="$t('metaData.fields.naturalUser.job.selfEmployedSector.label')"
          :optionsList="branchList"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.job.selfEmployedSector')
              ? niceErrorMessage(errors.naturalUser.job.selfEmployedSector)
              : ''
          "
          valueKey="value"
          :nextBtnClicked="nextBtnClicked"
          valueLabel="text"
        />
      </v-col>

      <!--
      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.milesId"
          :label="$t('metaData.fields.naturalUser.milesId.label')"
          :placeholder="$t('metaData.fields.naturalUser.milesId.placeholder')"
          :error="checkIfExists('errors.naturalUser.milesId') ? niceErrorMessage(errors.naturalUser.milesId) : ''"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
      -->

    </v-row>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import SelectComponent from '@/components/reusable/Select/Select.vue';

export default {
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  data() {
    return {
      jobList: [
        { value: 'employee', text: this.$t('employmentStatus.industry.employee') },
        { value: 'selfEmployed', text: this.$t('employmentStatus.industry.selfEmployed') },
        { value: 'publicOfficial', text: this.$t('employmentStatus.industry.publicOfficial') },
        { value: 'student', text: this.$t('employmentStatus.industry.student') },
        { value: 'privateer', text: this.$t('employmentStatus.industry.privateer') },
        { value: 'pensioner', text: this.$t('employmentStatus.industry.pensioner') },
      ],
      branchList: [
        { value: '01000', text: this.$t('selfEmployedSector.industry.agriculture') },
        { value: '05100', text: this.$t('selfEmployedSector.industry.mining') },
        { value: '23000', text: this.$t('selfEmployedSector.industry.glassware') },
        { value: '29000', text: this.$t('selfEmployedSector.industry.motorVehicleIndustry') },
        { value: '35000', text: this.$t('selfEmployedSector.industry.energySupply') },
        { value: '36000', text: this.$t('selfEmployedSector.industry.waterSupplyDisposal') },
        { value: '41000', text: this.$t('selfEmployedSector.industry.buildingConstruction') },
        { value: '45000', text: this.$t('selfEmployedSector.industry.tradeMotorVehicle') },
        { value: '47000', text: this.$t('selfEmployedSector.industry.retailTrade') },
        { value: '52000', text: this.$t('selfEmployedSector.industry.transportServices') },
        { value: '56000', text: this.$t('selfEmployedSector.industry.gastronomy') },
        { value: '63000', text: this.$t('selfEmployedSector.industry.informationServices') },
        { value: '68000', text: this.$t('selfEmployedSector.industry.realEstateHousing') },
        { value: '74000', text: this.$t('selfEmployedSector.industry.freelanceScientificActivities') },
        { value: '82000', text: this.$t('selfEmployedSector.industry.economicServices') },
        { value: '84000', text: this.$t('selfEmployedSector.industry.publicAdministration') },
        { value: '85000', text: this.$t('selfEmployedSector.industry.educationTeaching') },
        { value: '86000', text: this.$t('selfEmployedSector.industry.healthCare') },
        { value: '90000', text: this.$t('selfEmployedSector.industry.artisticActivities') },
        { value: '93200', text: this.$t('selfEmployedSector.industry.serviceSportEntertainment') },
      ],
      statusList: [
        { value: 'single', text: this.$t('metaData.fields.naturalUser.maritalStatus.options.single') },
        { value: 'married', text: this.$t('metaData.fields.naturalUser.maritalStatus.options.married') },
        {
          value: 'civil_partnership',
          text: this.$t('metaData.fields.naturalUser.maritalStatus.options.civilPartnership'),
        },
        { value: 'divorced', text: this.$t('metaData.fields.naturalUser.maritalStatus.options.divorced') },
      ],
    };
  },
  methods: {
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'additionalInformationCard',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditComponent,
    SelectComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

