<template>
  <!-- used in profile view -->
  <EditComponent
    class="naturalUser basic"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('metaData.fields.naturalUser.label')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
  >
    <v-row>
      <v-col cols="12" sm="4">
        <InputComponent
          v-model="naturalUser.phoneMobile"
          :label="$t('metaData.fields.naturalUser.phoneMobile.label')"
          :placeholder="$t('metaData.fields.naturalUser.phoneMobile.label')"
          :error="
            checkIfExists('errors.naturalUser.phoneMobile') ? niceErrorMessage(errors.naturalUser.phoneMobile) : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <EditEmail :readonly="readonly"/>
      </v-col>
      <v-col cols="12" sm="4">
        <InputComponent
          v-model="naturalUser.phone"
          :label="$t('metaData.fields.naturalUser.phone.label')"
          :placeholder="$t('metaData.fields.naturalUser.phone.label')"
          :error="checkIfExists('errors.naturalUser.phone') ? niceErrorMessage(errors.naturalUser.phone) : ''"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import EditEmail from '@/components/reusable/Input/EditEmail';
import { nationalityOptions } from '@/enum/countries';

export default {
  name: 'ContactInformation',
  data() {
    return {
      nationalityOptions: nationalityOptions().map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      })),
    };
  },
  props: {
    limitedFields: {
      type: String,
      default: 'contactInformation',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  methods: {
    onChange() {
      this.onSave();
    },
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
  },
  components: {
    EditComponent,
    InputComponent,
    EditEmail,
  },
};
</script>

<style scoped lang="scss">
</style>

