var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"naturalUser basic",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.label')},on:{"loading-update":_vm.onLoadUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.phoneMobile.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.phoneMobile.label'),"error":checkIfExists('errors.naturalUser.phoneMobile') ? niceErrorMessage(errors.naturalUser.phoneMobile) : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text","readonly":_vm.readonly},model:{value:(naturalUser.phoneMobile),callback:function ($$v) {_vm.$set(naturalUser, "phoneMobile", $$v)},expression:"naturalUser.phoneMobile"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('EditEmail',{attrs:{"readonly":_vm.readonly}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.phone.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.phone.label'),"error":checkIfExists('errors.naturalUser.phone') ? niceErrorMessage(errors.naturalUser.phone) : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text","readonly":_vm.readonly},model:{value:(naturalUser.phone),callback:function ($$v) {_vm.$set(naturalUser, "phone", $$v)},expression:"naturalUser.phone"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }