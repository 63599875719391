<template>
  <EditComponent
    class="birth-view"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('metaData.fields.naturalUser.birth.label')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
  >
    <v-row>
      <v-col cols="12" sm="5">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.birth.dateOfBirth"
          :label="$t('metaData.fields.naturalUser.birth.dateOfBirth.label')"
          :error="
            checkIfExists('errors.naturalUser.birth.dateOfBirth')
              ? dobValidation(naturalUser.birth.dateOfBirth) || niceErrorMessage(errors.naturalUser.birth.dateOfBirth)
              : ''
          "
          :placeholder="$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder')"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="date"
        />
      </v-col>

      <v-col cols="12" sm="7">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.birth.placeOfBirth"
          :label="$t('metaData.fields.naturalUser.birth.placeOfBirth.label')"
          :placeholder="$t('metaData.fields.naturalUser.birth.placeOfBirth.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.birth.placeOfBirth')
              ? niceErrorMessage(errors.naturalUser.birth.placeOfBirth)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
      <v-col class="wallet-col">
        <AutoCompleteComponent
          :readonly="readonly"
          v-model="naturalUser.birth.countryOfBirth"
          :label="$t('metaData.fields.naturalUser.birth.countryOfBirth.label')"
          :placeholder="$t('metaData.fields.naturalUser.birth.countryOfBirth.label')"
          :optionsList="nationalityOptions"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.birth.countryOfBirth')
              ? niceErrorMessage(errors.naturalUser.birth.countryOfBirth)
              : ''
          "
          :nextBtnClicked="nextBtnClicked"
          valueKey="code"
          valueLabel="country"
        />
      </v-col>
    </v-row>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';

import { nationalityOptions } from '@/enum/countries';
import dobValidation from '@/utils/validations/dobValidation';

export default {
  name: 'BirthInformation',
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  data() {
    return {
      nationalityOptions: nationalityOptions().map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      })),
    };
  },
  props: {
    limitedFields: {
      type: String,
      default: 'birthInformation',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditComponent,
    AutoCompleteComponent,
    InputComponent,
  },
  methods: {
    dobValidation,
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

