<template>
  <EditComponent
    class="legitimation-document-view"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('metaData.fields.naturalUser.legitimationDocument.label')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
  >
    <v-row class="wallet-row">
      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.legitimationDocument.number"
          :label="$t('metaData.fields.naturalUser.legitimationDocument.number.label')"
          :placeholder="$t('metaData.fields.naturalUser.legitimationDocument.number.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.legitimationDocument.number')
              ? niceErrorMessage(errors.naturalUser.legitimationDocument.number)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
          :disabled="isIdentified"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.legitimationDocument.authority"
          :label="$t('metaData.fields.naturalUser.legitimationDocument.authority.label')"
          :placeholder="$t('metaData.fields.naturalUser.legitimationDocument.authority.label')"
          :error="
            checkIfExists('errors.naturalUser.legitimationDocument.authority')
              ? niceErrorMessage(errors.naturalUser.legitimationDocument.authority)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
          :disabled="isIdentified"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.legitimationDocument.createdAt"
          :label="$t('metaData.fields.naturalUser.legitimationDocument.createdAt.label')"
          :placeholder="$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.legitimationDocument.createdAt')
              ? niceErrorMessage(errors.naturalUser.legitimationDocument.createdAt)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="date"
          :disabled="isIdentified"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.legitimationDocument.expiresAt"
          :label="$t('metaData.fields.naturalUser.legitimationDocument.expiresAt.label')"
          :placeholder="$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder')"
          :error="
            checkIfExists('errors.naturalUser.legitimationDocument.expiresAt')
              ? niceErrorMessage(errors.naturalUser.legitimationDocument.expiresAt)
              : ''
          "
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="date"
          :disabled="isIdentified"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <AutoCompleteComponent
          :readonly="readonly"
          v-model="naturalUser.legitimationDocument.countryOfIssue"
          :label="$t('metaData.fields.naturalUser.legitimationDocument.countryOfIssue.label')"
          :placeholder="$t('metaData.fields.naturalUser.legitimationDocument.countryOfIssue.label')"
          :optionsList="countryOptions"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.legitimationDocument.countryOfIssue')
              ? niceErrorMessage(errors.naturalUser.legitimationDocument.countryOfIssue)
              : ''
          "
          :nextBtnClicked="nextBtnClicked"
          valueKey="code"
          valueLabel="country"
          :disabled="isIdentified"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <SelectComponent
          :readonly="readonly"
          v-model="naturalUser.legitimationDocument.type"
          :init="getDefaultDocType(naturalUser)"
          :label="$t('metaData.fields.naturalUser.legitimationDocument.type.label')"
          :optionsList="documentTypes"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.legitimationDocument.type')
              ? niceErrorMessage(errors.naturalUser.legitimationDocument.type)
              : ''
          "
          :nextBtnClicked="nextBtnClicked"
          valueKey="value"
          valueLabel="label"
          :disabled="isIdentified"
        />
      </v-col>
      <v-col v-if="!isIdentified" cols="12">
        <v-btn
          target="_self"
          @click.prevent="() => $router.push('/onboarding/ident')"
          color="accent"
          class="ml-4"
          small
        >
          re-identify</v-btn
        >
      </v-col>
    </v-row>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import SelectComponent from '@/components/reusable/Select/Select.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';

import { countryOptions } from '@/enum/countries';
import { mapGetters } from 'vuex';

export default {
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  methods: {
    getDefaultDocType: function (naturalUser) {
      if (!naturalUser.legitimationDocument.type) naturalUser.legitimationDocument.type = this.documentTypes[0].value;

      return naturalUser.legitimationDocument.type;
    },
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
  },
  computed: {
    ...mapGetters(['isIdentified']),
  },
  data() {
    return {
      countryOptions: countryOptions().map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      })),
      documentTypes: [
        {
          value: 'TravelPassport',
          text: this.$t('metaData.fields.naturalUser.legitimationDocument.type.options.travelPassport'),
        },
        { value: 'Id', text: this.$t('metaData.fields.naturalUser.legitimationDocument.type.options.id') },
      ],
    };
  },
  props: {
    limitedFields: {
      type: String,
      default: 'legitimationDocument',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AutoCompleteComponent,
    EditComponent,
    InputComponent,
    SelectComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

