<template>
  <!-- used in profile view -->
  <EditComponent
    class="naturalUser basic"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('metaData.fields.naturalUser.label')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
  >
    <v-row>
      <v-col cols="12" sm="6">
        <InputComponent
          v-model="naturalUser.firstName"
          :label="$t('metaData.fields.naturalUser.firstName.label')"
          :placeholder="$t('metaData.fields.naturalUser.firstName.label')"
          :error="checkIfExists('errors.naturalUser.firstName') ? niceErrorMessage(errors.naturalUser.firstName) : ''"
          :tooltip="isIdentified ? $t('metaData.fields.naturalUser.firstName.tooltip') : null"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
          :disabled="isIdentified"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <InputComponent
          v-model="naturalUser.lastName"
          :label="$t('metaData.fields.naturalUser.lastName.label')"
          :placeholder="$t('metaData.fields.naturalUser.lastName.placeholder')"
          :error="checkIfExists('errors.naturalUser.lastName') ? niceErrorMessage(errors.naturalUser.lastName) : ''"
          :tooltip="isIdentified ? $t('metaData.fields.naturalUser.lastName.tooltip') : null"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
          :disabled="isIdentified"
          :readonly="readonly"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <SelectComponent
          v-model="naturalUser.title"
          :label="$t('metaData.fields.naturalUser.title.label')"
          :optionsList="titleList"
          :placeholder="$t('metaData.fields.naturalUser.title.placeholder')"
          :onSelect="onSave"
          :error="checkIfExists('errors.naturalUser.title') ? niceErrorMessage(errors.naturalUser.title) : ''"
          :nextBtnClicked="nextBtnClicked"
          valueKey="value"
          valueLabel="text"
          :readonly="readonly"
          clearable
        />
      </v-col>
      <v-col cols="12" sm="6">
        <SelectComponent
          v-model="naturalUser.sex"
          :label="$t('metaData.fields.naturalUser.sex.label')"
          :optionsList="sexList"
          :placeholder="$t('metaData.fields.naturalUser.sex.placeholder')"
          :onSelect="onSave"
          :error="checkIfExists('errors.naturalUser.sex') ? niceErrorMessage(errors.naturalUser.sex) : ''"
          :nextBtnClicked="nextBtnClicked"
          valueKey="value"
          valueLabel="sex"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <SelectComponent
          :readonly="readonly"
          v-model="naturalUser.citizenship"
          :label="$t('metaData.fields.naturalUser.citizenship.label')"
          :optionsList="nationalityOptions"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.citizenship') ? niceErrorMessage(errors.naturalUser.citizenship) : ''
          "
          :nextBtnClicked="nextBtnClicked"
          valueKey="code"
          valueLabel="country"
        />
      </v-col>
    </v-row>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import SelectComponent from '@/components/reusable/Select/Select.vue';
import { nationalityOptions } from '@/enum/countries';
import { sexList, titleList } from '@/enum/userOptions';

export default {
  name: 'GeneralInformation',
  data() {
    return {
      nationalityOptions: nationalityOptions().map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      })),
      sexList,
      titleList,
    };
  },
  computed: {
    isIdentified: function () {
      // if identification is made basic info data will be overwritten by data from the ident service
      return this.$store.getters.isIdentified;
    },
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    limitedFields: {
      type: String,
      default: 'generalInformation',
    },
  },
  methods: {
    onChange() {
      this.onSave();
    },
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
  },
  components: {
    EditComponent,
    InputComponent,
    SelectComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

