<template>
  <EditComponent
    class="tax-information"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('taxes.title')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
  >
    <v-row>
      <v-col cols="12" sm="4">
        <AutoCompleteComponent
          :readonly="readonly"
          v-model="naturalUser.taxableCountry"
          :label="`${$t('metaData.fields.naturalUser.taxableCountry.label')}*`"
          :placeholder="`${$t('metaData.fields.naturalUser.taxableCountry.label')}`"
          :optionsList="countriesOptions"
          :onSelect="onSave"
          :error="
            checkIfExists('errors.naturalUser.taxableCountry') ? niceErrorMessage(errors.naturalUser.taxableCountry) : ''
          "
          :nextBtnClicked="nextBtnClicked"
          valueKey="code"
          valueLabel="country"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.taxId"
          :label="$t('metaData.fields.naturalUser.taxId.label')"
          :placeholder="$t('metaData.fields.naturalUser.taxId.placeholder')"
          :error="checkIfExists('errors.naturalUser.taxId') ? niceErrorMessage(errors.naturalUser.taxId) : ''"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
          :tooltip="$t('metaData.fields.naturalUser.taxId.tooltip')"
        />
      </v-col>
      
    </v-row>
  </EditComponent>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';
import { countryOptions, blacklistedCountries } from '@/enum/countries';

export default {
  name: 'TaxesInformation',
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  data() {
    const defaultCountries = ['DE', 'AT', 'NL', 'CH', 'FR', 'IT', 'DK'];

    const countriesOptions = countryOptions()
      .filter((item) => {
        return !defaultCountries.includes(item.code);
      })
      .map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      }));

    const countriesOptionsDefault = defaultCountries.map((item) => {
      const country = countryOptions().find((countryObj) => {
        return countryObj.code == item;
      });

      return {
        value: country.code,
        text: country.country,
      };
    });

    return {
      countriesOptions: [...countriesOptionsDefault, ...countriesOptions],
    };
  },

  computed: {
    getIsUSTaxed: {
      get(value) {
        return !value.$children[0].naturalUser.hasOwnProperty('isUSTaxed')
          ? false
          : !value.$children[0].naturalUser.isUSTaxed;
      },
      set(value) {
        this.$children[0].errors = null;
        this.$children[0].naturalUser.isUSTaxed = !value;
      },
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'taxes',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
  },
  components: {
    AutoCompleteComponent,
    EditComponent,
    InputComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

