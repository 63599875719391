<template>
  <LayoutSTP>
    <div class="profile-view mt-15 mb-12 pa-4 pt-8 pb-12" v-if="!loading">
      <v-row class="mb-4 row flex-column">
        <h5 class="title h5 mb-2">{{ $t('profile.title') }}</h5>
        <p class="body-1">
          {{ $t('profile.intro') }}
        </p>
      </v-row>
      <section>
        <v-row class="mt-8 mb-5 d-flex row align-center section-title">
          <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>person</v-icon>
          <h5 class="d-flex ml-2 h5">{{ $t('metaData.fields.naturalUser.label') }}</h5>
          <v-spacer />
          <v-btn
            icon
            color="primary"
            :small="$vuetify.breakpoint.xs"
            @click.prevent="editingMode.generalInformation = !editingMode.generalInformation"
          >
            <v-icon>{{ editingMode.generalInformation ? 'edit_off' : 'edit' }}</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-card class="pa-4 mb-5" :loading="loaders.generalInformation">
            <GeneralInformation :readonly="!editingMode.generalInformation" @loading-update="(value) => loaders.generalInformation = value"/>
          </v-card>
        </v-row>
      </section>
      <section>
        <v-row class="mt-8 mb-5 d-flex row align-center section-title">
          <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>phone</v-icon>
          <h5 class="d-flex ml-2 h5">{{ $t('profile.contactInformation') }}</h5>
          <v-spacer />
          <v-btn
            icon
            color="primary"
            :small="$vuetify.breakpoint.xs"
            @click.prevent="editingMode.contactInformation = !editingMode.contactInformation"
          >
            <v-icon>{{ editingMode.contactInformation ? 'edit_off' : 'edit' }}</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-card class="pa-4 mb-5" :loading="loaders.contactInformation">
            <ContactInformation :readonly="!editingMode.contactInformation" @loading-update="(value) => loaders.contactInformation = value"/>
          </v-card>
        </v-row>
      </section>
      <section>
        <v-row>
          <v-col cols="12" md="6">
            <v-row class="mt-8 mb-5 d-flex row align-center section-title">
              <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>home</v-icon>
              <h5 class="d-flex ml-2 h5">{{ $t('metaData.fields.naturalUser.address.label') }}</h5>
              <v-spacer />
              <v-btn
                icon
                color="primary"
                :small="$vuetify.breakpoint.xs"
                @click.prevent="editingMode.addressInformation = !editingMode.addressInformation"
              >
                <v-icon>{{ editingMode.addressInformation ? 'edit_off' : 'edit' }}</v-icon>
              </v-btn>

            </v-row>
            <v-row>
              <v-card class="pa-4 mb-5" :loading="loaders.addressInformation">
                <AddressInformation :readonly="!editingMode.addressInformation" @loading-update="(value) => loaders.addressInformation = value"/>
              </v-card>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row class="mt-8 mb-5 d-flex row align-center section-title">
              <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>cake</v-icon>
              <h5 class="d-flex ml-2 h5">{{ $t('metaData.fields.naturalUser.birth.label') }}</h5>
              <v-spacer />
              <v-btn
                icon
                color="primary"
                :small="$vuetify.breakpoint.xs"
                @click.prevent="editingMode.birthInformation = !editingMode.birthInformation"
              >
                <v-icon>{{ editingMode.birthInformation ? 'edit_off' : 'edit' }}</v-icon>
              </v-btn>

            </v-row>
            <v-row>
              <v-card class="pa-4 mb-5" :loading="loaders.birthInformation">
                <BirthInformation :readonly="!editingMode.birthInformation" @loading-update="(value) => loaders.birthInformation = value"/>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </section>
      <section>
        <v-row class="mt-8 mb-5 d-flex row align-center section-title">
          <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>verified_user</v-icon>
          <h5 class="d-flex ml-2 h5" :class="$vuetify.breakpoint.mdAndUp ? '' : 'flex-column'">
            {{ $t('metaData.fields.naturalUser.legitimationDocument.label') }}
            <v-btn
              target="_self"
              v-if="!isIdentified"
              @click.prevent="() => $router.push('/onboarding/ident')"
              color="accent"
              :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : ''"
              small
            >
              <v-icon dark>warning</v-icon>{{ $t('identification.expired') }}
            </v-btn>

          </h5>
          <v-spacer />
          <v-btn
            icon
            color="primary"
            :small="$vuetify.breakpoint.xs"
            @click.prevent="editingMode.legitimationDocument = !editingMode.legitimationDocument"
            disabled
          >
            <v-icon>{{ editingMode.legitimationDocument ? 'edit_off' : 'edit' }}</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-card class="pa-4 mb-5" :loading="loaders.legitimationDocument">
            <LegitimationDocument :readonly="!editingMode.legitimationDocument" @loading-update="(value) => loaders.legitimationDocument = value" />
          </v-card>
        </v-row>
      </section>
      <section>
        <v-row class="mt-8 mb-5 d-flex row align-center section-title">
          <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>euro</v-icon>
          <h5 class="d-flex ml-2 h5">{{ $t('taxes.title') }}</h5>
          <v-spacer />
          <v-btn
            icon
            color="primary"
            :small="$vuetify.breakpoint.xs"
            @click.prevent="editingMode.taxesInformation = !editingMode.taxesInformation"
          >
            <v-icon>{{ editingMode.taxesInformation ? 'edit_off' : 'edit' }}</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-card class="pa-4 mb-5" :loading="loaders.taxesInformation">
            <TaxesInformation :readonly="!editingMode.taxesInformation" @loading-update="(value) => loaders.taxesInformation = value" />
          </v-card>
        </v-row>
      </section>
      <section>
        <v-row class="mt-8 mb-5 d-flex row align-center section-title">
          <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>attach_money</v-icon>
          <h5 class="d-flex ml-2 h5">{{ $t('menu.rewardsInformation') }}</h5>
          <v-spacer />
          <v-btn
            icon
            color="primary"
            :small="$vuetify.breakpoint.xs"
            @click.prevent="editingMode.rewardsInformation = !editingMode.rewardsInformation"
          >
            <v-icon>{{ editingMode.rewardsInformation ? 'edit_off' : 'edit' }}</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-card class="pa-4 mb-5" :loading="loaders.rewardsInformation">
            <RewardsInformation :readonly="!editingMode.rewardsInformation" @loading-update="(value) => loaders.rewardsInformation = value" />
          </v-card>
        </v-row>
      </section>
      <section>
        <v-row class="mt-8 mb-5 d-flex row align-center section-title">
          <v-icon class="d-flex primary pa-2 ml-0 rounded-circle" dark>info</v-icon>
          <h5 class="d-flex ml-2 h5">{{ $t('metaData.fields.naturalUser.additionalInformation') }}</h5>
          <v-spacer />
          <v-btn
            icon
            color="primary"
            :small="$vuetify.breakpoint.xs"
            @click.prevent="editingMode.additionalInformation = !editingMode.additionalInformation"
          >
            <v-icon>{{ editingMode.additionalInformation ? 'edit_off' : 'edit' }}</v-icon>
          </v-btn>

        </v-row>
        <v-row>
          <v-card class="pa-4 mb-5" :loading="loaders.additionalInformation">
            <AdditionalInformation :readonly="!editingMode.additionalInformation" @loading-update="(value) => loaders.additionalInformation = value" />
          </v-card>
        </v-row>
      </section>
    </div>
  </LayoutSTP>
</template>


<script>
import * as _ from 'lodash';
import { mapGetters } from 'vuex';
import LayoutSTP from '@/views/LayoutSTP.vue';
import AddressInformation from '@/components/UserMetadata/Address.vue';
import AdditionalInformation from '@/components/UserMetadata/AdditionalInformation.vue';
import BirthInformation from '@/components/UserMetadata/Birth.vue';
import GeneralInformation from '@/components/UserMetadata/GeneralInformation.vue';
import ContactInformation from '@/components/UserMetadata/ContactInformation.vue';
import LegitimationDocument from '@/components/UserMetadata/LegitimationDocument.vue';
import TaxesInformation from '@/components/UserMetadata/TaxesInformation.vue';
import RewardsInformation from '@/components/UserMetadata/RewardsInformation.vue';

export default {
  components: {
    LayoutSTP,
    AddressInformation,
    AdditionalInformation,
    BirthInformation,
    ContactInformation,
    GeneralInformation,
    LegitimationDocument,
    TaxesInformation,
    RewardsInformation,
  },
  data() {
    return {
      naturalUser: {},
      loading: true,
      loaders: {
        addressInformation: false,
        additionalInformation: false,
        birthInformation: false,
        contactInformation: false,
        generalInformation: false,
        legitimationDocument: false,
        taxesInformation: false,
        rewardsInformation: false,
      },
      editingMode: {
        addressInformation: false,
        additionalInformation: false,
        birthInformation: false,
        contactInformation: false,
        generalInformation: false,
        legitimationDocument: false,
        taxesInformation: false,
        rewardsInformation: false,
      },
    };
  },
  async mounted() {
    this.$store.commit('setLoaded', { loaded: true });
    this.loading = false;
  },

  computed: {
    ...mapGetters(['isIdentified']),
  },
};
</script>

<style scoped lang="scss">
@import '@/style/media';
@import '@/style/colors';

.profile-view {
  .v-card {
    width: 100%;
    z-index: 0;
    @include media('<=md') {
      width: 100vw;
      max-width: 100vw;
      margin: 0 -20px;
    }
  }
  > section {
    position: relative;
    flex: 1;
    .row {
      &.section-title {
        background: color('background', 'light');
        @include media('<=md') {
          position: sticky;
          top: 56px;
          z-index: 1;
          padding: 8px 12px;
          margin: 0px -32px;
        }
      }
    }
  }
  *[class*='col-'] {
    &:last-of-type {
      @include media('>sm') {
        padding-left: 32px;
      }
    }
  }
  .h5 {
    word-break: break-word;
    &.title {
      display: flex;
      flex: 1;
    }
    @include media('<sm') {
      width: 70%;
      max-width: 70%;
    }
  }
}
#app {
  .profile-view {
    .h5 {
      word-break: break-word;
      &:not(.title) {
        @include media('<=md') {
          font-size: 1rem !important;
        }
      }
    }
  }
}
</style>