var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"birth-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.birth.label')},on:{"loading-update":_vm.onLoadUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.birth.dateOfBirth.label'),"error":checkIfExists('errors.naturalUser.birth.dateOfBirth')
            ? _vm.dobValidation(naturalUser.birth.dateOfBirth) || niceErrorMessage(errors.naturalUser.birth.dateOfBirth)
            : '',"placeholder":_vm.$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder'),"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"date"},model:{value:(naturalUser.birth.dateOfBirth),callback:function ($$v) {_vm.$set(naturalUser.birth, "dateOfBirth", $$v)},expression:"naturalUser.birth.dateOfBirth"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.birth.placeOfBirth.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.birth.placeOfBirth.placeholder'),"error":checkIfExists('errors.naturalUser.birth.placeOfBirth')
            ? niceErrorMessage(errors.naturalUser.birth.placeOfBirth)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text"},model:{value:(naturalUser.birth.placeOfBirth),callback:function ($$v) {_vm.$set(naturalUser.birth, "placeOfBirth", $$v)},expression:"naturalUser.birth.placeOfBirth"}})],1),_c('v-col',{staticClass:"wallet-col"},[_c('AutoCompleteComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.birth.countryOfBirth.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.birth.countryOfBirth.label'),"optionsList":_vm.nationalityOptions,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.birth.countryOfBirth')
            ? niceErrorMessage(errors.naturalUser.birth.countryOfBirth)
            : '',"nextBtnClicked":nextBtnClicked,"valueKey":"code","valueLabel":"country"},model:{value:(naturalUser.birth.countryOfBirth),callback:function ($$v) {_vm.$set(naturalUser.birth, "countryOfBirth", $$v)},expression:"naturalUser.birth.countryOfBirth"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }