var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"legitimation-document-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.legitimationDocument.label')},on:{"loading-update":_vm.onLoadUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('v-row',{staticClass:"wallet-row"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.legitimationDocument.number.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.legitimationDocument.number.placeholder'),"error":checkIfExists('errors.naturalUser.legitimationDocument.number')
            ? niceErrorMessage(errors.naturalUser.legitimationDocument.number)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text","disabled":_vm.isIdentified},model:{value:(naturalUser.legitimationDocument.number),callback:function ($$v) {_vm.$set(naturalUser.legitimationDocument, "number", $$v)},expression:"naturalUser.legitimationDocument.number"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.legitimationDocument.authority.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.legitimationDocument.authority.label'),"error":checkIfExists('errors.naturalUser.legitimationDocument.authority')
            ? niceErrorMessage(errors.naturalUser.legitimationDocument.authority)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text","disabled":_vm.isIdentified},model:{value:(naturalUser.legitimationDocument.authority),callback:function ($$v) {_vm.$set(naturalUser.legitimationDocument, "authority", $$v)},expression:"naturalUser.legitimationDocument.authority"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.legitimationDocument.createdAt.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder'),"error":checkIfExists('errors.naturalUser.legitimationDocument.createdAt')
            ? niceErrorMessage(errors.naturalUser.legitimationDocument.createdAt)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"date","disabled":_vm.isIdentified},model:{value:(naturalUser.legitimationDocument.createdAt),callback:function ($$v) {_vm.$set(naturalUser.legitimationDocument, "createdAt", $$v)},expression:"naturalUser.legitimationDocument.createdAt"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.legitimationDocument.expiresAt.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.birth.dateOfBirth.placeholder'),"error":checkIfExists('errors.naturalUser.legitimationDocument.expiresAt')
            ? niceErrorMessage(errors.naturalUser.legitimationDocument.expiresAt)
            : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"date","disabled":_vm.isIdentified},model:{value:(naturalUser.legitimationDocument.expiresAt),callback:function ($$v) {_vm.$set(naturalUser.legitimationDocument, "expiresAt", $$v)},expression:"naturalUser.legitimationDocument.expiresAt"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('AutoCompleteComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.legitimationDocument.countryOfIssue.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.legitimationDocument.countryOfIssue.label'),"optionsList":_vm.countryOptions,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.legitimationDocument.countryOfIssue')
            ? niceErrorMessage(errors.naturalUser.legitimationDocument.countryOfIssue)
            : '',"nextBtnClicked":nextBtnClicked,"valueKey":"code","valueLabel":"country","disabled":_vm.isIdentified},model:{value:(naturalUser.legitimationDocument.countryOfIssue),callback:function ($$v) {_vm.$set(naturalUser.legitimationDocument, "countryOfIssue", $$v)},expression:"naturalUser.legitimationDocument.countryOfIssue"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('SelectComponent',{attrs:{"readonly":_vm.readonly,"init":_vm.getDefaultDocType(naturalUser),"label":_vm.$t('metaData.fields.naturalUser.legitimationDocument.type.label'),"optionsList":_vm.documentTypes,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.legitimationDocument.type')
            ? niceErrorMessage(errors.naturalUser.legitimationDocument.type)
            : '',"nextBtnClicked":nextBtnClicked,"valueKey":"value","valueLabel":"label","disabled":_vm.isIdentified},model:{value:(naturalUser.legitimationDocument.type),callback:function ($$v) {_vm.$set(naturalUser.legitimationDocument, "type", $$v)},expression:"naturalUser.legitimationDocument.type"}})],1),(!_vm.isIdentified)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"target":"_self","color":"accent","small":""},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$router.push('/onboarding/ident'); })($event)}}},[_vm._v(" re-identify")])],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }