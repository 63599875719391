import { render, staticRenderFns } from "./RewardsInformation.vue?vue&type=template&id=baf03bda&scoped=true&"
import script from "./RewardsInformation.vue?vue&type=script&lang=js&"
export * from "./RewardsInformation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baf03bda",
  null
  
)

export default component.exports