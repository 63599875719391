var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"tax-information",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('taxes.title')},on:{"loading-update":_vm.onLoadUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('AutoCompleteComponent',{attrs:{"readonly":_vm.readonly,"label":((_vm.$t('metaData.fields.naturalUser.taxableCountry.label')) + "*"),"placeholder":("" + (_vm.$t('metaData.fields.naturalUser.taxableCountry.label'))),"optionsList":_vm.countriesOptions,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.taxableCountry') ? niceErrorMessage(errors.naturalUser.taxableCountry) : '',"nextBtnClicked":nextBtnClicked,"valueKey":"code","valueLabel":"country"},model:{value:(naturalUser.taxableCountry),callback:function ($$v) {_vm.$set(naturalUser, "taxableCountry", $$v)},expression:"naturalUser.taxableCountry"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.taxId.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.taxId.placeholder'),"error":checkIfExists('errors.naturalUser.taxId') ? niceErrorMessage(errors.naturalUser.taxId) : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text","tooltip":_vm.$t('metaData.fields.naturalUser.taxId.tooltip')},model:{value:(naturalUser.taxId),callback:function ($$v) {_vm.$set(naturalUser, "taxId", $$v)},expression:"naturalUser.taxId"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }