<template>
 <div class="main-section-content">
    <main class="wrapper" :class="$route.name.toLowerCase()" :key="$i18n.language">
      <slot />
    </main>
  </div>
</template>

<script>
  import axios from 'axios';
  import { gtmTrack, gtmActions } from '@/utils/gmt';

  export default {
    data: () => {
      return {
        
      };
    },
    methods: {
      
    },
    computed: {
      
    },
  };
</script>

<style lang="scss">

</style>
