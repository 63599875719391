<template>
  <EditComponent
    class="additional-information-view"
    v-bind:limitedFields="limitedFields"
    v-bind:title="$t('menu.rewardsInformation')"
    v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, nextBtnClicked }"
    @loading-update="onLoadUpdate"
  >
    <v-row>        
      <v-col cols="12" sm="4">
        <InputComponent
          :readonly="readonly"
          v-model="naturalUser.milesId"
          :label="$t('metaData.fields.naturalUser.milesId.label')"
          :placeholder="$t('metaData.fields.naturalUser.milesId.placeholder')"
          :error="checkIfExists('errors.naturalUser.milesId') ? niceErrorMessage(errors.naturalUser.milesId) : ''"
          :onChange="onSave"
          :nextBtnClicked="nextBtnClicked"
          inputType="text"
        />
      </v-col>
    </v-row>
  </EditComponent>
</template>

<script>
import InputComponent from '@/components/reusable/Input/Input.vue';
import EditComponent from '@/components/UserMetadata/EditComponent.vue';

export default {
  name: 'RewardsInformation',
  mounted() {
    this.$store.commit('setLoaded', { loaded: true });
  },
  props: {
    limitedFields: {
      type: String,
      default: 'rewardsInformation',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditComponent,
    InputComponent,
  },
  methods: {
    onLoadUpdate(loading) {
      this.$emit('loading-update', loading);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

