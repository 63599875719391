var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"additional-information-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.additionalInformation')},on:{"loading-update":_vm.onLoadUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('SelectComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.maritalStatus.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.maritalStatus.label'),"optionsList":_vm.statusList,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.maritalStatus') ? niceErrorMessage(errors.naturalUser.maritalStatus) : '',"nextBtnClicked":nextBtnClicked,"valueKey":"value","valueLabel":"label"},model:{value:(naturalUser.maritalStatus),callback:function ($$v) {_vm.$set(naturalUser, "maritalStatus", $$v)},expression:"naturalUser.maritalStatus"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('SelectComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.job.group.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.job.group.label'),"optionsList":_vm.jobList,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.job.group') ? niceErrorMessage(errors.naturalUser.job.group) : '',"nextBtnClicked":nextBtnClicked,"valueKey":"value","valueLabel":"job"},model:{value:(naturalUser.job.group),callback:function ($$v) {_vm.$set(naturalUser.job, "group", $$v)},expression:"naturalUser.job.group"}})],1),(naturalUser.job.group == 'selfEmployed')?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('SelectComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.job.selfEmployedSector.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.job.selfEmployedSector.label'),"optionsList":_vm.branchList,"onSelect":onSave,"error":checkIfExists('errors.naturalUser.job.selfEmployedSector')
            ? niceErrorMessage(errors.naturalUser.job.selfEmployedSector)
            : '',"valueKey":"value","nextBtnClicked":nextBtnClicked,"valueLabel":"text"},model:{value:(naturalUser.job.selfEmployedSector),callback:function ($$v) {_vm.$set(naturalUser.job, "selfEmployedSector", $$v)},expression:"naturalUser.job.selfEmployedSector"}})],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }