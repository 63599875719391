var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditComponent',{staticClass:"additional-information-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('menu.rewardsInformation')},on:{"loading-update":_vm.onLoadUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('InputComponent',{attrs:{"readonly":_vm.readonly,"label":_vm.$t('metaData.fields.naturalUser.milesId.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.milesId.placeholder'),"error":checkIfExists('errors.naturalUser.milesId') ? niceErrorMessage(errors.naturalUser.milesId) : '',"onChange":onSave,"nextBtnClicked":nextBtnClicked,"inputType":"text"},model:{value:(naturalUser.milesId),callback:function ($$v) {_vm.$set(naturalUser, "milesId", $$v)},expression:"naturalUser.milesId"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }